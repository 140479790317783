.card {
    font-size: 20px;
    margin-top: 10%;
    margin-bottom: 20%;
    background-color: white;
    padding: 10px;
    width: 60%;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
    text-align: center;
    border-radius: 16px;
  }
  
.dc-card-screen {
    background-image: linear-gradient(to top, rgb(255, 255, 255), rgb(62, 191, 255));
    display: flex;
    justify-content: center;
    align-items: center;
  }
  