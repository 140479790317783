.production_supervisor_dashboard .site_layout_content {
   min-height: 100vh;
   padding: 24px;
   /* background: #fff; */
   background-image: linear-gradient(to top, rgb(255, 255, 255), rgba(62, 191, 255, 0.534));
  }

 .production_supervisor_dashboard{
  /* background: aqua; */
  background-image: linear-gradient(to top, rgb(255, 255, 255), rgba(46, 185, 255, 0.412));
}
 
 .layout{
  background: aqua;
 }

 .bgwhite{
  background: white;

 }

 .production_supervisor_dashboard .site_card_wrapper {
   padding: 30px;
   background: #ececec;
   display: flex;
   flex-wrap: wrap;
   gap: 1em;  
   /* background_image: linear_gradient(295deg, #3f0d12 0%, #a71d31 74%); */
 }
 
 .production_supervisor_dashboard .site_card_wrapper>div{
   flex: 1 1 30%;
   min-width: 150px;
 }
 
 .production_supervisor_dashboard .ant_card_body{
   padding: 20px 24px 24px 24px;
 }
 
 .shift_handover_card .ant_card_body{
   display: flex;
   justify-content: space_evenly;
   align-items: center;
   
 }

 .dropdown_icon{
   margin-left: 10px;
 }

 .container_graph{
  height: 180vh;
  padding: 1vh;
  margin: 2%;
 }

 .row{
  margin-bottom: 3%;
 }

 .summary_tables{
    font-size: large;
 }