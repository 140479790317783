.login {
  display: flex;
  align-items: center;
  justify-content: center;  
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(190,71,44);
  background: radial-gradient(circle, rgba(190,71,44,1) 0%, rgba(214,20,20,1) 100%);
}

.input {
  border-radius: 5px;
  width: 100%;
}

.login .ant-card-body{
  padding: 5px 24px 24px 24px;
}

.image {
  width: 40%;
  height: 5%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -0.5em;
}

.ant-card.ant-card-bordered.card{
  height: 60%;
  width: 25%;
}

.login-form{
  text-align: center;
  justify-content: center;
  align-items: center;
  
}

@media (max-width: 768px) {
  .ant-card.ant-card-bordered.card{
    height: 40%;
    width: 70%;
  }
  .image{
    width: 20%;
  }
}

@media (max-width: 400px) {
  .ant-card.ant-card-bordered.card{
    height: 55%;
    width: 80%;
  }
}
