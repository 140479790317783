.card-holder {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3f0d12;
  background-image: linear-gradient(295deg, #3f0d12 0%, #a71d31 74%);
  position: relative;
}

.form-card {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: white;
  padding: 20px;
  width: 60%;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  text-align: center;
  border-radius: 16px;
}

.back-btn {
  position: absolute;
  top: 4.2%;
  left: 20px;
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.site-card-wrapper {
  padding: 30px;
  background: #ececec;
}

.gutter-box {
  padding: 8px 0;
  background: #00a0e9;
}

.action-button {
  width: 140px;
  height: 45px;
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 500;

  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
}

.approve-btn {
  background-color: #00cc66;
  color: #fff;
}

.reject-btn {
  background-color: #fff;
}

.action-button:hover {
  background-color: #2ee59d;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translateY(-7px);
}

.action-button:visited {
  background-color: #000;
}
