.production-supervisor-dashboard .site-layout-content {
   min-height: 100vh;
   padding: 24px;
   background: #fff;
 }
 
 .production-supervisor-dashboard .site-card-wrapper {
   padding: 30px;
   background: #ececec;
   display: flex;
   flex-wrap: wrap;
   gap: 1em;  
   /* background-image: linear-gradient(295deg, #3f0d12 0%, #a71d31 74%); */
 }
 
 .production-supervisor-dashboard .site-card-wrapper>div{
   flex: 1 1 30%;
   min-width: 150px;
 }
 
 .production-supervisor-dashboard .ant-card-body{
   padding: 20px 24px 24px 24px;
 }
 
 .shift-handover-card .ant-card-body{
   display: flex;
   justify-content: space-evenly;
   align-items: center;
   
 }

 .dropdown-icon{
   margin-left: 10px;
 }

 .container-graph{
  height: 180vh;
  padding: 1vh;
  margin: 2%;
 }

 .row{
  margin-bottom: 3%;
 }

 .summary-tables{
    font-size: large;
 }