.site-card-wrapper {
  padding: 30px;
  background: #ececec;
  align-items: center;
  justify-content: center;
}

.card {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 10px;
  margin: 15;
}

.machine-dashboard {
  overflow-x: hidden;
}

.right-side{
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
