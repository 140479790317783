Button{
    margin: 0 5px;
 }
 
 .modal-input-form>div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
 }
 
 .modal-input-form>div>div:nth-child(1){
    text-align: left;
 }
 
 .modal-input-form>div>div{
    flex: 1 1 45%;
 }