.back-btn {
   position: absolute;
   top: 3.5%;
   left: 20px;
   color: #fff;
   border-color: #3F33E2;
   background: #3F33E2;
   text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
   box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
 }

 .back-btn-2 {
   position: absolute;
   top: 3%;
   right: 20px;
   color: #fff;
   border-color: #3F33E2;
   background: #3F33E2;
   text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
   box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
 }