/* #components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
} */

/* sidebar ends */
.site-layout-content {
  min-height: 100vh;
  padding: 24px;
  background: #fff;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.site-card-wrapper {
  padding: 30px;
  background: #ececec;
  display: flex;
  flex-wrap: wrap;
  margin: 0 !important;
  width: 100% !important;
  gap: 1em;  
  /* background-image: linear-gradient(295deg, #3f0d12 0%, #a71d31 74%); */
}

.site-card-wrapper>div{
  flex: 1 1 30%;
  min-width: 150px;
}

.operator-dashboard .ant-card-body{
  padding: 20px 24px 24px 24px;
}

.shift-handover-card .ant-card-body{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  
}

.alert-card{
  margin-top: 1em;
}

@media (max-width: 976px) {
  .shift-handover-card .ant-card-body{
    flex-direction: column !important;
    gap: 1em;
  }
}

@media (max-width: 768px) {
  .site-card-wrapper>div{
    flex: 1 1 100%;    
  }
}