/* #components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
} */

/* sidebar ends */
.site_layout_content {
    min-height: 100vh;
    padding: 50px;   
    background-image: linear-gradient(to top, rgb(255, 255, 255), rgb(62, 191, 255));

  }
  #components_layout_demo_top .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
  }
  .ant_row_rtl #components_layout_demo_top .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
  .site_card_wrapper {
    padding: 30px;
    background: #ececec;
    display: flex;
    flex-wrap: wrap;
    margin: 0 !important;
    width: 100% !important;
    gap: 1em;  
    /* background-image: linear-gradient(295deg, #3f0d12 0%, #a71d31 74%); */
  }
  
  .site_card_wrapper>div{
    flex: 1 1 30%;
    min-width: 150px;
  }
  
  .operator_dashboard .ant_card_body{
    padding: 20px 24px 24px 24px;
  }
  
  .shift_handover_card .ant_card_body{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    
  }
  .operator_dashboard{
    /* background: aqua; */
    background-image: linear-gradient(to top, rgb(255, 255, 255), rgb(62, 191, 255));

  }
  
  .alert_card{
    margin-top: 1em;
  }

  
  @media (max-width: 976px) {
    .shift_handover_card .ant_card_body{
      flex-direction: column !important;
      gap: 1em;
    }
  }
  
  @media (max-width: 768px) {
    .site_card_wrapper>div{
      flex: 1 1 100%;    
    }
  }