.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.card-holder {
  min-height: 200vh;
  min-width: 200vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3f0d12;
  background-image: linear-gradient(295deg, #3f0d12 0%, #a71d31 74%);
  position: relative;  
}
.form-card-1 {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: white;
  padding: 20px;
  width: 90% !important;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  text-align: left !important;
  border-radius: 16px;  
}

.card-holder {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3f0d12;
  background-image: linear-gradient(295deg, #3f0d12 0%, #a71d31 74%);
  position: relative;
}
.form-card {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: white;
  padding: 20px;
  width: 90%;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  text-align: left;
  border-radius: 16px;  
}
.sub-headers {
  font-weight: bold;
  text-align: left;
  padding-bottom: 12px;
}

.divider {
  height: 5px;
  width: 50%;
}

.logout-btn {
  position: absolute;
  top: 20px;
  right: 40px;
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.submit-btn{
  padding: 20px 0 0 0;
  text-align: center;
}
.form-row{
  padding: 10px 0;
}

table, th, td {
border: 2px solid #f0f0f0 !important;
}

.training-table{
  width: 100%;
  margin-bottom: 5px !important;  
}

.training{
  width: 100%;
  overflow-x: scroll;
}


.training-table th, .training-table td{
  padding: 10px;
  min-width: 170px;
}

.training-card{
  width: 80% !important;
}

.ant-pagination-item{
  margin-right: 0;
}


