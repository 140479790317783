.App {
  text-align: center;
}

::-webkit-scrollbar {
  width: 1.2em;
}

::-webkit-scrollbar-track {
  background: (255, 255, 255, 0.7);
  border-radius: 100vw;
  margin-block: 0.1em;
}

::-webkit-scrollbar-thumb {
  background: #757575;
  border-radius: 100vw;
  
}

::-webkit-scrollbar-thumb:hover {
  background: #909090;
}
