.heading-qasupervisor{
  font-family: Georgia, sans-serif;
  font-size: 3em;
  letter-spacing: -2px;
  margin: 1%;
}

.site-card-wrapper {
  padding: 30px;
  background: #ececec;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  width: 30%;
  margin-left: 10%;
  /* background-image: linear-gradient(295deg, #3f0d12 0%, #a71d31 74%); */
}

.site-card-wrapper > div {
  flex: 1 1 30%;
  min-width: 150px;
}

.operator-dashboard .ant-card-body {
  padding: 20px 24px 24px 24px;
}

.shift-handover-card .ant-card-body {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 976px) {
  .shift-handover-card .ant-card-body {
    flex-direction: column !important;
    gap: 1em;
  }
}

@media (max-width: 768px) {
  .site-card-wrapper > div {
    flex: 1 1 100%;
  }
}
