.card_holder{
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  background-image: linear-gradient(to top, #000000 0%, #ff0000 74%);
  position: relative;
}
.form_card {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  width: 60%;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  text-align: center;
  border-radius: 16px;
}
.sub_headers {
  font-weight: bold;
  text-align: left;
  padding-bottom: 12px;
  display: block !important;
}

.divider {
  height: 5px;
  width: 50%;
}

.submit_btn{   
  text-align: center;
}
