body {
	overflow-x: hidden !important;
	background-color: white;
}

.card-holder {
	min-height: 100vh;
	min-width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #3f0d12;
	background-image: linear-gradient(295deg, #3f0d12 0%, #a71d31 74%);
	position: relative;
}
.form-card-prod-manager {
	margin-top: 50px;
	margin-bottom: 50px;
	background-color: rgb(255, 255, 255);
	padding: 20px;
	width: 90% !important;
	box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
	text-align: left !important;
	border-radius: 16px;
}
.sub-header {
	font-weight: bold;
	text-align: center !important;
	padding-bottom: 20px;
}

.divider {
	height: 5px;
	width: 50%;
}

.logout-btn {
	position: absolute;
	top: 20px;
	right: 40px;
	color: #fff;
	border-color: #ff4d4f;
	background: #ff4d4f;
	text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
	box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.submit-btn {
	padding: 20px 0 0 0;
	text-align: center;
}
.form-row {
	padding: 10px 0;
}

.th-day {
	text-align: left;
}

.form-item {
	margin-bottom: 0 !important;
}
.margin-top-reset {
	margin-top: -15%;
}

.margin-top-set {
	margin-top: 6%;
}
