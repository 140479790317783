.form-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 10px;
  width: 40%;
  height: 35%;
}

.back-btn-2 {
  position: absolute;
  top: 4.2%;
  right: 20px;
  color: #fff;
  border-color: #ff4d4f;
  background: #ff4d4f;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
