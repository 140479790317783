
.gm-dashboard{
    display: flex;
}

.left-side{
    display: flex;
    flex-wrap: wrap;
}

.right-side{
    margin-top: 10px;
}

.r-table table{
    width:100% ;
}

.r-table{
    margin: 5px;
}


#lt{
    padding: 10px;
}

.color-bars{
    margin: 10px;
    display: flex;
    justify-content:left;
    text-align: center;
    width: 95%;
    gap:2rem;
    @media screen and (max-width:900px) {
        gap: 1rem;
    }
}

.summary-tables{
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
}

.summary-tables div{
    margin: 10px;
}

.summary-tables tr *{
    padding: 5px;
}

#l-title{
    color: rgb(17, 123, 245);
}

#table-bar{
    margin-bottom: 20px;
    width: 100%;
}

.bar{
    width: 100px;
    margin-bottom: 4rem;

    @media screen and (min-width:900px) {
        width:200px;
    }
}
.bar-label{
    padding: 10px;
    border: 2px solid rgb(28, 96, 241);
    margin: 2px;
    height: 40px;
}

.bar button label{
    cursor: pointer;
}

.bar-label label{
    cursor: pointer;
}


#table-bar tr{
    border: 1px solid lightblue;
    
}

.red-bar{
    padding: 10px;
    background-color:rgba(253, 0, 0, 0.8)
}

.orange-bar{
    padding: 10px;
    background-color: rgb(255, 166, 0,0.8);
}

.yellow-bar{
    padding: 10px;
    background-color: rgba(248, 248, 83, 0.8);
}

.green-bar{
    padding: 10px;
    background-color: rgb(1, 230, 1,0.8);
}

.bar button{
    font-weight: 1000;
    color: rgb(85, 74, 74);
    background-color: transparent;
    border: none;
}

.alert-summary button, .operations-kpi button{
    font-weight: 800;
    color: rgb(69, 206, 224);
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.qrci-bar button, .pm-bar button{
    font-weight: 800;
    color: rgb(255, 255, 255);
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.bar button:hover{
    color: blue;
    cursor:pointer;
}

.r-table a{
    font-weight: 1000;
    color: rgb(255, 255, 255);
}
