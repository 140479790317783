.maintenance-forms {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.maintenance-card {
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
	border-radius: 10px;
	width: 40%;
	height: 35%;
}
