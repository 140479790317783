.card-holder {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3f0d12;
  background-image: linear-gradient(295deg, #3f0d12 0%, #a71d31 74%);
  position: relative;
}
.form-card {
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: white;
  padding: 20px;
  width: 95%;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  text-align: center;
  border-radius: 16px;
}
.sub-headers {
  font-weight: bold;
  text-align: left;
  padding-bottom: 12px;
}

.divider {
  height: 5px;
  width: 50%;
}

.submit-btn {
  text-align: center;
}

.add-button {
  margin-top: 10px;
}

.add-button > button {
  width: 50%;
}

.qrci-form{
 width: 80% !important;
}



.tracking-dropdown{
  display: flex; 
  gap: 1em;
  flex-direction: column;
}

#classification{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.tracking-tabs .ant-tabs{
  align-items: center !important;
}