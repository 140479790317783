.huf-nav-bar.ant-layout-header {
  height: 60px !important;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #24252a;
  padding: 0 2em;
  height: 80px !important;
  box-shadow: 0 1px 1px rgb(16 27 30 / 15%), 0 2px 2px rgb(16 27 30 / 15%),
    0 3px 3px rgb(16 27 30 / 15%), 0 4px 4px rgb(16 27 30 / 15%),
    0 16px 16px rgb(16 27 30 / 15%) !important;
}

.right-content {
  display: flex;
  gap: 2.5em;
  margin: 0 1.5em;
}

.right-content > div {
  position: relative;
}

.nav-ul {
  position: absolute;
  top: 130%;
  right: 0;
  padding: 0.3em !important;
  list-style-type: none;
  width: 20ch;
  text-align: left;
  background: white;
  margin: auto;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgb(16 27 30 / 40%);
  border: 0.1px solid #f0ffff;
  z-index: 9999 !important;
  transition: 0.5s;
}

.on-hover {
  transition: 0.5s;
}

.nav-ul:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: white;
  right: 4.2px;
  top: -5px;
  transform: rotate(45deg);
}

.nav-li {
  padding: 0.3em 0.3em 0.3em 0.5em;
}

.nav-ul > li + li {
  margin-top: 10px;
}

.logout:hover {
  cursor: pointer;
}

.logout > p {
  margin-bottom: 0;
}

/* notifications  */

.icon {
  margin-left: 15px;
  cursor: pointer;
  position: relative;
}

.iconImg {
  width: 20px;
  height: 20px;
}

.counter {
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 75%;
  padding: 5px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -5px;
}
